<template>
  <div class="container" style="border: 1px solid black; padding: 1%">
    <div class="row mt-2">
      <h3 style="color: #000;">Canvas Grade Report Data</h3>
      <span style="font-style: italic;">This will show the users and their grade per assignment
      based on who you have access to and the date range you select.</span>
    </div>
    <div class="row mt-2">
      <h5 style="color: black;">Date Range</h5>
      <div class="col-sm">
        <label>Start Date</label>
        <DatePicker v-model="startDate" :min-date="earliestPossibleDate" autoApply :enable-time-picker="false" textInput uid="startDate"/>
      </div>
      <div class="col-sm">
        <label></label>
        <p>The date range you search for will filter results between the two dates.</p>
      </div>
      <div class="col-sm">
        <label>End Date</label>
        <DatePicker v-model="endDate" :min-date="minEndDate" autoApply :enable-time-picker="false" textInput uid="endDate"/>
      </div>
    </div>
    <div>
      <button class="button bi-download btn-primary" :disabled="!downloadButtonEnabled" @click="exportDataHelper">
        Grade CSV Report
      </button>
    </div>
  </div>
</template>

<script>
import {computed, ref} from 'vue';
import {appStartDate, defaultStartDate} from "@/Helpers/DateHelper";
import Swal from "sweetalert2";
import {exportCertEmailScopedWithDates} from "@/Helpers/ApiCalls/ReportAPICalls";
import {useStore} from "vuex";
import axios from 'axios';
import DatePicker from "@vuepic/vue-datepicker";

export default {
  name: "CanvasReports",
  components: { DatePicker},
  setup() {
    const endDate = ref(new Date());
    const startDate = ref(defaultStartDate());
    const downloadedData = ref(true);
    const earliestPossibleDate = ref(appStartDate());
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];

    const minEndDate = computed(() => {
      let default_date = startDate.value !== null ? startDate.value.toDateString() : earliestPossibleDate.value.toDateString();
      let min_date = new Date(default_date);
      let result = min_date.setDate(min_date.getDate() + 1);
      return new Date(result);
    })

    function exportDataHelper() {
      downloadedData.value = false;

      Swal.fire(({
        title: "Download Data?",
        text: "Report data will be emailed to your MyESU login email. You will not be able to resubmit a request for the data until you receive this request",
        confirmButtonText: "Okay",
        cancelButtonText: "Cancel",
        showCancelButton: true
      })).then((result) => {
        if (result.isConfirmed) {
          sendDataExport()
        } else {
          downloadedData.value = true;
        }
      })
    }

    async function sendDataExport() {
      let formatted_start = startDate.value.toISOString().slice(0, 10);
      let formatted_end = endDate.value.toISOString().slice(0, 10);
      await axios.get(exportCertEmailScopedWithDates(), {
        params: {
          _startDate: formatted_start,
          _endDate: formatted_end
        },
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: 'Report Sent',
            text: "Check the email you log into Project Para with for the report",
            confirmButtonText: "Okay"
          })
        }
      }).finally(() => {
        downloadedData.value = true;
      })
    }

    const downloadButtonEnabled = computed(() => {
      return downloadedData.value && startDate.value !== null && endDate.value !== null;
    })

    return {
      startDate,
      endDate,
      minEndDate,
      downloadButtonEnabled,
      earliestPossibleDate,
      exportDataHelper
    }
  }
}
</script>

<style scoped>
span {
  color: black;
  font-style: italic;
}
</style>