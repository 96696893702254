import {API_URL} from "../../../Constants";

export const certificationsByParaCall = () => {
	return API_URL + "/certification/byParaId?_idPara=";
}

export const updateCertificationByParaCall = () => {
	return API_URL + "/certification/updateByParaId?_idPara=";
}

export const updateCertificationByUserCall = (idUser) => {
	return API_URL + `/certification/updateByUserId?_idUser=${idUser}`;
}


export const getCertificationByUserId = (idUser) => {
	return API_URL + `/certification/byUserId?_idUser=${idUser}`;
}

export const allParticipationInRange = () => {
	return API_URL + "/reports/aggregate/participating/range";
}

export const nebraskaStateWideParticipationInRange = () => {
	return API_URL + "/reports/aggregate/participating/nebraska/range";
}

export const participationByCountryInRange = () => {
	return API_URL + "/reports/aggregate/participating/byCountry/range";
}

export const participationByStateInRange = () => {
	return API_URL + "/reports/aggregate/participating/byState/range";
}

export const participationByEsuInRange = () => {
	return API_URL + "/reports/aggregate/participating/range/byEsu?_idEsu=";
}

export const participationBySchoolInRange = () => {
	return API_URL + "/reports/aggregate/participating/range/bySchool?_idSchool=";
}

export const nebraskaByDistrictParticipationInRange = () => {
	return API_URL + "/reports/aggregate/participating/range/byDistrict?_idDistrict=";
}

export const updateCertificationsByUserAndCourse = (idUser, canvasCourseId) => {
	return `${API_URL}/certification/course/${canvasCourseId}/updateByUserId/${idUser}`
}

export const getCertificationsByCourse = (canvasCourseId) => {
	return `${API_URL}/certification/allByCourse/${canvasCourseId}`
}

export const getCertificationByUserAndCourse = (idUser, canvasCourseId) => {
	return `${API_URL}/certification/course/${canvasCourseId}/getByUserId/${idUser}`
}

export const exportCertEmail = () => {
	return `${API_URL}/certification/requestCertificationReport`
}

export const exportCertEmailWithDates = () => {
	return `${API_URL}/certification/requestCertificationReportInDates`
}

export const exportCertEmailScopedWithDates = () => {
	return `${API_URL}/certification/requestCertificationReportScoped`;
}

export const historicalCertByKeyword = (keyword) => {
	return `${API_URL}/historical/certs?searchTerm=${keyword}`;
}
