<template>
  <div class="container-fluid">
    <h1>Report Dashboard</h1>
    <h6><span style="font-style: italic">All reports will be sent to your registered Project Para email as a csv file, this can take a while depending on the data.</span></h6>
    <div class="container" style="border: 1px solid black; padding: 1%">
      <div class="row mt-2">
        <h3 style="color: #000;">Registration Reports</h3>
        <span style="font-style: italic">The data you download will reflect the user registration based on your access level.</span>
      </div>
      <div class="row mt-2">
        <DataExportComponent />
      </div>
    </div>
    <CanvasReports />
  </div>
</template>

<script>
import DataExportComponent from "@/components/Reporting/DataExportComponent.vue";
import CanvasReports from "@/views/SuperUserViews/Reporting/CanvasParaReports/CanvasReports.vue";

export default {
  name: "ReportDashboard",
  components: {CanvasReports, DataExportComponent},
  setup() {

  }
}
</script>

<style scoped>
span {
  color: black;
  font-style: italic;
}
</style>