<template>
  <div>
    <button class="button btn-primary bi-download" :disabled="!downloadButtonEnabled" @click="showAlert" > Registration Report</button>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import axios from "axios";
import Swal from "sweetalert2";
import {registrationExport} from "@/Helpers/ApiCalls/UserAPICalls";

export default {
  name: "DataExportComponent",
  setup() {
    const store = useStore();
    const authToken = store.getters['auth/authenticated'];
    const downloadButtonEnabled = ref(true);

    function showAlert() {
      downloadButtonEnabled.value = false;
      let msg = `<span>Report data will be emailed to your MyESU login email. You will not be able to resubmit a request for the data until you receive this request. This will grab all data you have access to, not necessarily reflect what is seen in the datatable.</span>`
      Swal.fire(({
        title: "Download Data?",
        html: msg,
        confirmButtonText: "Okay",
        cancelButtonText: "Cancel",
        showCancelButton: true
      })).then((result) => {
        if(result.isConfirmed) {
          requestEmail()
        } else {
          downloadButtonEnabled.value = true;
        }
      })
    }

    async function requestEmail() {
      await axios.get(registrationExport(), {
        headers: {
          "Authorization": `Bearer ${authToken.value}`
        }
      }).then((result) => {
        if (result.status === 200) {
          Swal.fire({
            title: 'Report Sent',
            text: "Check the email you log into Project Para with for the report",
            confirmButtonText: "Okay"
          })
        }
      }).finally(() => {
        downloadButtonEnabled.value = true;
      })
    }

    const buttonStyle = computed(() => {
      return downloadButtonEnabled.value ? `color: #593196; text-decoration: underline;` : `color: black; text-decoration: underline;`
    })

    return {
      downloadButtonEnabled,
      buttonStyle,
      showAlert,

    }
  }
}
</script>

<style scoped>

</style>